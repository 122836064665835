import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e609bb2c = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _f04a8e60 = () => interopDefault(import('../pages/AboutUs.vue' /* webpackChunkName: "" */))
const _720be3d0 = () => interopDefault(import('../pages/CareerOpportunities.vue' /* webpackChunkName: "" */))
const _39ac59bc = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _101c427e = () => interopDefault(import('../pages/Catalog-Request-Form.vue' /* webpackChunkName: "" */))
const _4814cb65 = () => interopDefault(import('../pages/Contact.vue' /* webpackChunkName: "" */))
const _dc6d0b82 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _ab26e6e6 = () => interopDefault(import('../pages/Error.vue' /* webpackChunkName: "" */))
const _6a3625db = () => interopDefault(import('../pages/Faq.vue' /* webpackChunkName: "" */))
const _0b735d02 = () => interopDefault(import('../pages/Privacy-Policy.vue' /* webpackChunkName: "" */))
const _6950672e = () => interopDefault(import('../pages/Terms-Conditions.vue' /* webpackChunkName: "" */))
const _d51bf23c = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _2d058e2e = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _68963f8e = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _993323ea = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _c6178c9c = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _28e70695 = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _3fcc6ae4 = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _3514d6a6 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _34805e81 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyApplications.vue' /* webpackChunkName: "" */))
const _34995c97 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _1db57bac = () => interopDefault(import('../modules/customer/pages/MyAccount/MyPaymentMethods.vue' /* webpackChunkName: "" */))
const _04b6e3e9 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _9f7985a6 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _57596123 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _465207ed = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _23c80ce8 = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _46a21f39 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _c4b24e7e = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _17ff24e6 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _459f8fcc = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _0ebc91e6 = () => interopDefault(import('../pages/SeoSitemapCatalog.vue' /* webpackChunkName: "pages/SeoSitemapCatalog" */))
const _1c46b888 = () => interopDefault(import('../pages/SeoSitemapProduct.vue' /* webpackChunkName: "pages/SeoSitemapProduct" */))
const _9ac875a2 = () => interopDefault(import('../modules/catalog/pages/categorySearch.vue' /* webpackChunkName: "" */))
const _90fce000 = () => interopDefault(import('../modules/checkout/components/Error.vue' /* webpackChunkName: "" */))
const _38f231c6 = () => interopDefault(import('../modules/checkout/components/VsfPaymentProvider.vue' /* webpackChunkName: "" */))
const _9f5bee78 = () => interopDefault(import('../modules/checkout/pages/Checkout/paypal/Success.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "",
    component: _e609bb2c,
    name: "home"
  }, {
    path: "about-hhip",
    component: _f04a8e60,
    name: "about-hhip"
  }, {
    path: "careers",
    component: _720be3d0,
    name: "careers"
  }, {
    path: "cart",
    component: _39ac59bc,
    name: "cart"
  }, {
    path: "Catalog-Request-Form",
    component: _101c427e,
    name: "Catalog-Request-Form"
  }, {
    path: "contacts",
    component: _4814cb65,
    name: "contacts"
  }, {
    path: "customer",
    component: _dc6d0b82,
    name: "customer"
  }, {
    path: "error.html",
    component: _ab26e6e6,
    name: "error_find____hhip"
  }, {
    path: "faq",
    component: _6a3625db,
    name: "faq"
  }, {
    path: "privacy-policy",
    component: _0b735d02,
    name: "privacy-policy"
  }, {
    path: "terms-conditions",
    component: _6950672e,
    name: "terms-conditions"
  }, {
    path: "/about-hhip",
    component: _f04a8e60,
    name: "about-hhip___"
  }, {
    path: "/AboutUs",
    component: _f04a8e60,
    name: "AboutUs"
  }, {
    path: "/CareerOpportunities",
    component: _720be3d0,
    name: "CareerOpportunities"
  }, {
    path: "/careers",
    component: _720be3d0,
    name: "careers___"
  }, {
    path: "/cart",
    component: _39ac59bc,
    name: "cart"
  }, {
    path: "/cart",
    component: _39ac59bc,
    name: "cart___"
  }, {
    path: "/Catalog-Request-Form",
    component: _101c427e,
    name: "Catalog-Request-Form___"
  }, {
    path: "/Catalog-Request-Form",
    component: _101c427e,
    name: "Catalog-Request-Form"
  }, {
    path: "/checkout",
    component: _d51bf23c,
    name: "checkout",
    children: [{
      path: "billing",
      component: _2d058e2e,
      name: "billing"
    }, {
      path: "payment",
      component: _68963f8e,
      name: "payment"
    }, {
      path: "shipping",
      component: _993323ea,
      name: "shipping"
    }, {
      path: "thank-you",
      component: _c6178c9c,
      name: "thank-you"
    }, {
      path: "user-account",
      component: _28e70695,
      name: "user-account"
    }]
  }, {
    path: "/Cms",
    component: _3fcc6ae4,
    name: "Cms"
  }, {
    path: "/Contact",
    component: _4814cb65,
    name: "Contact"
  }, {
    path: "/contacts",
    component: _4814cb65,
    name: "contacts___"
  }, {
    path: "/customer",
    component: _dc6d0b82,
    meta: {"titleLabel":"My Account"},
    name: "customer",
    children: [{
      path: "addresses-details",
      component: _3514d6a6,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details"
    }, {
      path: "my-applications",
      component: _34805e81,
      meta: {"titleLabel":"My applications"},
      name: "customer-my-applications"
    }, {
      path: "my-newsletter",
      component: _34995c97,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter"
    }, {
      path: "my-payment-methods",
      component: _1db57bac,
      meta: {"titleLabel":"My Payment Methods"},
      props: true,
      name: "my-payment-methods"
    }, {
      path: "my-profile",
      component: _04b6e3e9,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile"
    }, {
      path: "my-reviews",
      component: _9f7985a6,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews"
    }, {
      path: "my-wishlist",
      component: _57596123,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist"
    }, {
      path: "order-history",
      component: _465207ed,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history"
    }, {
      path: "/reset-password",
      component: _23c80ce8,
      alias: "/customer/account/createPassword",
      name: "reset-password"
    }, {
      path: "addresses-details/create",
      component: _46a21f39,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _c4b24e7e,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit"
    }, {
      path: "order-history/:orderId",
      component: _17ff24e6,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order"
    }]
  }, {
    path: "/customer",
    component: _dc6d0b82,
    name: "customer___"
  }, {
    path: "/Error",
    component: _ab26e6e6,
    name: "Error"
  }, {
    path: "/error.html",
    component: _ab26e6e6,
    name: "error_find____hhip___"
  }, {
    path: "/faq",
    component: _6a3625db,
    name: "faq___"
  }, {
    path: "/Faq",
    component: _6a3625db,
    name: "Faq"
  }, {
    path: "/Home",
    component: _e609bb2c,
    name: "Home"
  }, {
    path: "/Page",
    component: _459f8fcc,
    name: "Page"
  }, {
    path: "/privacy-policy",
    component: _0b735d02,
    name: "privacy-policy___"
  }, {
    path: "/Privacy-Policy",
    component: _0b735d02,
    name: "Privacy-Policy"
  }, {
    path: "/SeoSitemapCatalog",
    component: _0ebc91e6,
    name: "SeoSitemapCatalog"
  }, {
    path: "/SeoSitemapProduct",
    component: _1c46b888,
    name: "SeoSitemapProduct"
  }, {
    path: "/terms-conditions",
    component: _6950672e,
    name: "terms-conditions___"
  }, {
    path: "/Terms-Conditions",
    component: _6950672e,
    name: "Terms-Conditions"
  }, {
    path: "seo-sitemap/catalog.html",
    component: _0ebc91e6,
    name: "seo_sitemap"
  }, {
    path: "seo-sitemap/product.html",
    component: _1c46b888,
    name: "seo_sitemap_product"
  }, {
    path: "//AboutUs",
    component: _f04a8e60,
    name: "AboutUs___"
  }, {
    path: "//CareerOpportunities",
    component: _720be3d0,
    name: "CareerOpportunities___"
  }, {
    path: "//cart",
    component: _39ac59bc,
    name: "cart___"
  }, {
    path: "//Catalog-Request-Form",
    component: _101c427e,
    name: "Catalog-Request-Form___"
  }, {
    path: "//checkout",
    component: _d51bf23c,
    name: "checkout___",
    children: [{
      path: "billing",
      component: _2d058e2e,
      name: "billing___"
    }, {
      path: "payment",
      component: _68963f8e,
      name: "payment___"
    }, {
      path: "shipping",
      component: _993323ea,
      name: "shipping___"
    }, {
      path: "thank-you",
      component: _c6178c9c,
      name: "thank-you___"
    }, {
      path: "user-account",
      component: _28e70695,
      name: "user-account___"
    }]
  }, {
    path: "//Cms",
    component: _3fcc6ae4,
    name: "Cms___"
  }, {
    path: "//Contact",
    component: _4814cb65,
    name: "Contact___"
  }, {
    path: "//customer",
    component: _dc6d0b82,
    meta: {"titleLabel":"My Account"},
    name: "customer___",
    children: [{
      path: "addresses-details",
      component: _3514d6a6,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___"
    }, {
      path: "my-applications",
      component: _34805e81,
      meta: {"titleLabel":"My applications"},
      name: "customer-my-applications___"
    }, {
      path: "my-newsletter",
      component: _34995c97,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___"
    }, {
      path: "my-payment-methods",
      component: _1db57bac,
      meta: {"titleLabel":"My Payment Methods"},
      props: true,
      name: "my-payment-methods___"
    }, {
      path: "my-profile",
      component: _04b6e3e9,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___"
    }, {
      path: "my-reviews",
      component: _9f7985a6,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___"
    }, {
      path: "my-wishlist",
      component: _57596123,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___"
    }, {
      path: "order-history",
      component: _465207ed,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___"
    }, {
      path: "/reset-password",
      component: _23c80ce8,
      alias: "/customer/account/createPassword",
      name: "reset-password"
    }, {
      path: "addresses-details/create",
      component: _46a21f39,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___"
    }, {
      path: "//reset-password",
      component: _23c80ce8,
      alias: "/customer/account/createPassword",
      name: "reset-password___"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _c4b24e7e,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___"
    }, {
      path: "order-history/:orderId",
      component: _17ff24e6,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___"
    }]
  }, {
    path: "//Error",
    component: _ab26e6e6,
    name: "Error___"
  }, {
    path: "//Faq",
    component: _6a3625db,
    name: "Faq___"
  }, {
    path: "//Home",
    component: _e609bb2c,
    name: "Home___"
  }, {
    path: "//Page",
    component: _459f8fcc,
    name: "Page___"
  }, {
    path: "//Privacy-Policy",
    component: _0b735d02,
    name: "Privacy-Policy___"
  }, {
    path: "//SeoSitemapCatalog",
    component: _0ebc91e6,
    name: "SeoSitemapCatalog___"
  }, {
    path: "//SeoSitemapProduct",
    component: _1c46b888,
    name: "SeoSitemapProduct___"
  }, {
    path: "//Terms-Conditions",
    component: _6950672e,
    name: "Terms-Conditions___"
  }, {
    path: "/catalogsearch/result",
    component: _9ac875a2,
    name: "result search___"
  }, {
    path: "/seo-sitemap/catalog.html",
    component: _0ebc91e6,
    name: "seo_sitemap___"
  }, {
    path: "/seo-sitemap/product.html",
    component: _1c46b888,
    name: "seo_sitemap_product___"
  }, {
    path: "catalogsearch/result/",
    component: _9ac875a2,
    name: "result search"
  }, {
    path: "checkout/amazon/cancel",
    component: _90fce000,
    name: "amazon_cancel____hhip"
  }, {
    path: "checkout/amazon/success",
    component: _38f231c6,
    name: "amazon_success___hhip"
  }, {
    path: "checkout/paypal/cancel",
    component: _90fce000,
    name: "paypal_cancel____hhip"
  }, {
    path: "checkout/paypal/success",
    component: _9f5bee78,
    name: "paypal_success___hhip"
  }, {
    path: "checkout/stripe/error.html",
    component: _90fce000,
    name: "payment_error___hhip"
  }, {
    path: "customer/addresses-details/create",
    component: _46a21f39,
    name: "addresses-details/create"
  }, {
    path: "/checkout/amazon/cancel",
    component: _90fce000,
    name: "amazon_cancel____hhip___"
  }, {
    path: "/checkout/amazon/success",
    component: _38f231c6,
    name: "amazon_success___hhip___"
  }, {
    path: "/checkout/paypal/cancel",
    component: _90fce000,
    name: "paypal_cancel____hhip___"
  }, {
    path: "/checkout/paypal/success",
    component: _9f5bee78,
    name: "paypal_success___hhip___"
  }, {
    path: "/checkout/stripe/error.html",
    component: _90fce000,
    name: "payment_error___hhip___"
  }, {
    path: "/customer/addresses-details/create",
    component: _46a21f39,
    name: "addresses-details/create___"
  }, {
    path: "/",
    component: _e609bb2c,
    name: "home___"
  }, {
    path: "/customer/addresses-details/edit/:slug+",
    component: _c4b24e7e,
    name: "addresses-details/edit___"
  }, {
    path: "/checkout/thank-you/:slug+",
    component: _c6178c9c,
    name: "checkout/thank-you___"
  }, {
    path: "customer/addresses-details/edit/:slug+",
    component: _c4b24e7e,
    name: "addresses-details/edit"
  }, {
    path: "checkout/thank-you/:slug+",
    component: _c6178c9c,
    name: "checkout/thank-you"
  }, {
    path: "/:slug+",
    component: _459f8fcc,
    name: "page___"
  }, {
    path: ":slug+",
    component: _459f8fcc,
    name: "page"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
